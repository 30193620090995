@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@tailwind base;

@tailwind components;

@tailwind utilities;


// Base Components
//----------------------------------

@import 'components/v-tooltips.scss';

// Components
//----------------------------------

@import 'components/pace-loader';
@import 'components/animation';

.swal-icon--custom {
    height: 80px;
    width: 80px;
}

@media (max-width: 768px) {
    .table-component .sw-dropdown {
        position: absolute;
        visibility: visible;
        top: 15px;
        right: 10px;
    }
}

base,
html {
    font-family: 'montserrat';
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    font-weight: 300;
    overflow-x: hidden;
}

@font-face {
    font-family: 'Texas Tango';
    src: url('/fonts/TexasTango.eot'),
    url('/fonts/TexasTango.woff') format('woff'),
    url('/fonts/TexasTango.ttf') format('truetype'),
    url('/fonts/TexasTango.svg') format('svg');
    font-style: normal;
    font-weight: normal;
}

.swal2-container .swal2-popup {
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-delay: 150ms !important;
    padding: 1.5rem !important;
}

.swal2-actions {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    margin-top: 1.5rem !important;
    width: 100% !important;
    flex-wrap: nowrap !important;
    padding: 0 !important;
}

.swal2-content {
    text-align: center !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    color: #6b7280 !important;
    font-weight: 500 !important;
    margin-top: 0.5rem !important;
}

.swal2-icon {
    height: 3rem !important;
    width: 3rem !important;
    border: none !important;
    margin: 0 !important;
}

.swal2-header {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.swal2-title {
    text-align: center !important;
    margin-top: 0.75 !important;
    font-weight: 500 !important;
    color: #111827 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
    margin-top: 1.25rem;
}

.swal2-icon.swal2-error {
    background: #fed7d7 !important;
    border-radius: 9999px !important;
}

.swal2-icon.swal2-success {
    background: #c6f6d5 !important;
    border-radius: 9999px !important;
}

.swal2-icon.swal2-warning {
    background: #feebc8 !important;
    border-radius: 9999px !important;
}

.swal2-icon.swal2-info {
    background: #bee3f8 !important;
    border-radius: 9999px !important;
}

.swal2-icon.swal2-question {
    background: #edf2f7 !important;
    border-radius: 9999px !important;
}

.swal2-icon-content {
    font-size: 2.75em !important;
}

.swal2-title {
    margin: 0 !important;
    margin-top: 1.25rem !important;
}

.sidebar {
    background: #FFF4CF !important;
}

.main-content {
    background: #efe3bd !important;
}

.bg-white {
    background: #FFF4CF !important;
    border: none !important;
}

* {
    border-color: rgba(0, 0, 0, 0.1) !important;
}

.multiselect span.relative {
    border-color: rgb(165, 172, 193) transparent transparent !important;
}

input,
textarea {
    background: #FFF4CF !important;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.placeholder-gray-300::placeholder,
.placeholder-gray-400::placeholder,
.placeholder-gray-500::placeholder,
.placeholder-gray-600::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
}

.checkbox.bg-white,
.checkbox .bg-white {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.checkbox input[type="checkbox"]:checked {
    background: #5f021f !important;
}

.navigation-items * {
    color: #5f021f;
}

.bg-white.text-white {
    background: #5f021f !important;
    color: white !important;
}

.sidebar a.active,
.sidebar a:hover,
.sidebar a:focus {
    background: #efe3bd !important;
}

.sidebar a span {
    color: black;
}

.text-gray-800 {
    color: #5f021f;
}

.text-gray-500,
.text-gray-600,
.text-gray-400 {
    color: rgba(0, 0, 0, 0.5);
}

button.border-primary-500:hover,
button.border-primary-500:focus {
    color: white !important;
}

.editor__content .ProseMirror {
    border: none !important;
    border-radius: 4px;
}

.header-editior .editor-menu-bar {
    margin-left: 0 !important;
}

.login {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 20;
    background: #EFE3BDFF;
}

.login:before {
    content: '';
    background-image: url('/images/valley.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    mix-blend-mode: luminosity;
    opacity: .075;
}

.login label {
    font-weight: 800;
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
    text-transform: uppercase;
    font-family: 'Texas Tango';
    color: #5f021f;
}

.login .intro {
    margin-bottom: 20px;

    h3 {
        color: #5f021f;
        margin-bottom: 5px;
        font-family: 'Texas Tango';
        font-weight: 400;
        font-size: 27px;
    }

    h1{
        color: black;
        font-size: 40px;
        margin: 0 0 20px 0;
        line-height: 1.1;
        font-weight: 400;
        font-family: "Texas Tango", sans-serif;
    }
}

.login .forgot {
    text-decoration: underline;
}

.login > * {
    position: relative;
    z-index: 10;
}

.login .card {
    width: 100%;
    max-width: 500px;
    background: #FFF4CF;
    margin: 0 auto 20px auto;
    padding: 40px;
    border-radius: 15px;
    text-align: left;
    box-shadow: 5px 5px 0 #DACCA1;
}

.login #loginForm button {
    cursor: pointer;
    border-radius: 5px;
    color: white;
    line-height: 1;
    text-align: center;
    padding: 15px 20px;
    font-size: 1rem;
    appearance: none;
    -webkit-appearance: none;
    font-family: 'montserrat';
    display: block;
    width: 100%;
    z-index: 10;
    position: relative;
    border: none;
    background-image: url('/images/button-sign.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s ease-in-out;
}

.login input,
.login .bg-white {
    background: #f8ecc7 !important;
}

.login input {
    font-size: 16px;
    font-family: 'montserrat';
}

.customer-select-item:hover,
.customer-select-item:focus,
.new-customer-button {
    background: #efe3bd;
}

#loginForm > .relative {
    margin-bottom: 20px;
}

.main-header-logo {
    display: block;
    height: 35px;
}

.main-header-logo img {
    width: auto;
    height: 100%;
    object-fit: contain;
    object-position: left center;
}

.expenses input,
.expenses textarea {
    background: #efe3bd !important;
}

.expenses .multi-select-item,
.expenses .multi-select-item .bg-white,
.expenses .multi-select-item input {
    background: #efe3bd !important;
}

.customer-select > svg {
    background: #5f021f !important;
    fill: white !important;
}

.sw-modal-custom input,
.sw-modal-custom textarea {
    background: #efe3bd !important;
}

.customer-icon {
    background: #5f021f;
}

.multi-select-item {
    border-radius: 4px;
}

.multi-select-item,
.multi-select-item .bg-white,
.multi-select-item input,
.text-area-select {
    background: #efe3bd !important;
}

.multi-select-item .bg-gray-200 {
    border: none;
    background: #5f021f !important;
    color: white !important;
}

.multi-select-item .bg-gray-200 * {
    color: white !important;

}

.template-button * {
    color: white !important;
}

.template-button {
    border: none;
    background: #5f021f !important;
    color: white;
}

.invoice-total input {
    background: #efe3bd !important;
}
